import dayjs from "dayjs";
import {getOrg} from "./Util";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Date formatter to be used to display the org's times and dates according to the stored timezone.
 * @author HOPS
 * @copyright Heritage Operations Processing Limited
 */

/*
 * the locale language used to format the order of date and time components
 */
const locale = "en-GB"; // adhere to UK locale

/**
 * Get the current timezone of the org we are looking at.
 * @returns {object}
 */
export const getOrgTimezone = () => {
	const org = getOrg();
	return org?.Timezone ?? "Europe/London";
};

/**
 * Get the current date of the org we are looking at.
 * @returns {object}
 */
export const getOrgDate = () => dayjs(new Date()).tz(getOrgTimezone());

/**
 * Parse the date of the org into a dayjs object.
 * @returns {object}
 */
export const parseOrgDate = date => {
	if (!date) {
		return "";
	}
	const orgTimezone = getOrgTimezone();
	if (typeof date === "number") {
		date = dayjs(date * 1000).tz(orgTimezone); // epoch seconds to milliseconds;
	}
	return dayjs(date, orgTimezone).locale(locale);
};

/**
 * Convert date string/object to date string with custom date options, with spaces between values
 * @param {string|object} date
 * @param {object|null} options e.g. {weekday: "long", day: "numeric", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"}
 * @returns {string} formatted date
 */
const formatLocaleDateString = (date, format=null) => parseOrgDate(date).format(format);

/**
 * Convert date string/object to date string
 * @param {string|object} date
 * @returns {string} e.g. Tuesday 6 April 2021
 */
export const formatLongDate = date => formatLocaleDateString(date, "dddd D MMMM YYYY");

/**
 * Convert date string/object to date string
 * @param {string|object} date
 * @returns {string} e.g. Tue 6 Apr 2021
 */
export const formatDateTimetable = date => formatLocaleDateString(date, "ddd D MMM YYYY");

/**
 * Convert date string/object to short date string
 * @param {string|object} date
 * @returns {string} e.g. 24/03/2021
 */
export const formatShortDate = date => formatLocaleDateString(date, "DD/MM/YYYY");

/**
 * Convert date string/object to short date and time string
 * @param {string|object} date
 * @returns {string} e.g. 24/03/2021 15:30
 */
export const formatShortDateTime = date => formatLocaleDateString(date, "DD/MM/YYYY HH:mm");

/**
 * Convert start date and end date range to date string
 * If the years are different, the year will be included in the start date
 * @param {string|object} startDate
 * @param {string|object} endDate
 * @returns {string} e.g. Wednesday 1 November - Thursday 30 November 2023
 */
export const formatLongDateRange = (startDate, endDate) => {
	const sDate = dayjs(startDate);
	const eDate = dayjs(endDate);
	const sameYear = sDate.year() === eDate.year();

	const sDateFormatted = sameYear ?
		formatLocaleDateString(sDate, "dddd D MMMM") :
		formatLongDate(sDate);
	const eDateFormatted = formatLongDate(eDate);

	return `${sDateFormatted} - ${eDateFormatted}`;
};
